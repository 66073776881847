.snackbar-message-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 95vw;
}

.snackbar-logo {
  height: 20;
  width: 160;
  margin: 4;
}

.snackbar-message {
  width: 100%;
  display: flex;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}

.snackbar-message-type-wrapper {
  display: flex;
  align-items: center;
} 

.snackbar-message-icon {
  width: 24;
  height: 24;
  margin: 0 8;
}

.snackbar-message-icon-message {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-right: 6px;
}

.snackbar-message-content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.react-router-modal__container {
  position: unset;
  top: unset;
  left: unset;
  width: unset;
  height: unset;
  transform: unset;
}
.react-router-modal__wrapper .react-router-modal__backdrop {
  position: unset !important;
  top: unset !important;
  left: unset !important;
  width: unset !important;
  height: unset !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.react-router-modal__modal {
  position: unset !important;
  top: unset !important;
  left: unset !important;
  transform: unset !important;
  background-color: unset !important;
  border-radius: unset !important;
  border: unset !important
}

.snackbar-save-button {
  color: #3c3e49;
  display: flex;
  padding: 8px 32px;
  font-size: 16px;
  min-width: 128px;
  align-items: center;
  font-weight: 500;
  border-radius: 3px;
  text-transform: capitalize;
  justify-content: center;
  background-color: #00d659;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Rubik, sans-serif;
  line-height: 1.75;
}
.snackbar-discard-button {
  color: rgb(255, 255, 255);
  padding-right: 24px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: underline;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Rubik, sans-serif;
  line-height: 1.75;
  border-radius: 4px;
  border: 0;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
