.AppFooter {
  height: auto; 
  background-color: #FFF;
  display: flex;
  align-items: center;
  padding: 60px 120px 100px 120px;
  border-top: 1px solid #DFE0E6;
  position: relative;
  justify-content: center;
}

.AppFooter * {
  font-family: var(--design-body-text-font);
  color: var(--design-secondary-color);
}

.AppFooter a {
  text-decoration: none;
  color: inherit;
}

.AppFooter li:hover {
  opacity: 1;
  transition: opacity .3s;
}

.AppFooter__QuickLinks,
.AppFooter__Logo,
.AppFooter__UsefulLinks,
.AppFooter__SocialLinks,
.AppFooter__CustomerService {
  align-self: normal;
  margin-right: 55px;
  margin-left: 55px;
}

.AppFooter__Logo {
  max-width: 20%;
  cursor: default;
}

.AppFooter__Logo img {
  max-width: 200px;
  margin: 0 auto;
  max-height: 80px;
}

.AppFooter__FollowUsOn,
.AppFooter__DownloadApp {
  display: flex;
  flex-direction: column;
}

.AppFooter__QuickLinks li,
.AppFooter__UsefulLinks li,
.AppFooter__FollowUsOn li,
.AppFooter__CustomerService li,
.AppFooter__DownloadApp li {
  margin-bottom: 24px;
  cursor: pointer;
  white-space: nowrap;
  opacity: .6;
}

.AppFooter__CustomerService li {
  white-space: normal;
  width: 234px;
}

.AppFooter__QuickLinks ul,
.AppFooter__UsefulLinks ul,
.AppFooter__FollowUsOn ul,
.AppFooter__CustomerService ul,
.AppFooter__DownloadApp ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 24px;
}

.AppFooter__QuickLinks span,
.AppFooter__UsefulLinks span,
.AppFooter__FollowUsOn span,
.AppFooter__CustomerService span,
.AppFooter__DownloadApp span {
  font-weight: 500;
  line-height: 24px;
}

.AppFooter__FollowUsOn ul {
  display: inline-flex;
  gap: 0 12px;
  margin-top: 12px;
}

.AppFooter__FollowUsOn li {
  background-color: #E9E9E9;
  padding: 14px;
  border-radius: 50%;
  margin-bottom: 36px;
  opacity: 1;
}

.AppFooter__FollowUsOn li:hover {
  background-color: #DFE0E6;
  transition: background-color .3s;
}

.AppFooter__FollowUsOn li a {
  display: flex;
  align-items: center;
}

.AppFooter__FollowUsOn li a div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
}

.AppFooter__FollowUsOn li a div svg path {
  fill: var(--design-secondary-color);
}

.AppFooter__CopyRight {
  color: var(--design-secondary-color);
  opacity: .6;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.AppFooter__CopyRight__underlined {
  text-decoration: underline;
}

.AppFooter__DownloadApp ul {
  display: inline-flex;
  gap: 0 12px;
  margin-top: 12px;
}

.AppFooter__DownloadApp li {
  margin-bottom: 3px;
  opacity: 1;
}

.AppFooter__DownloadApp img {
  width: 120px;
  height: 40px;
}

.AppFooter__DownloadApp img:hover {
  transform: scale(1.01);
  transition: transform .3s;
}

@media (max-width: 1439px) {
  .AppFooter {
    padding: 80px 80px;
  }
  
  .AppFooter__QuickLinks,
  .AppFooter__Logo,
  .AppFooter__UsefulLinks,
  .AppFooter__SocialLinks,
  .AppFooter__CustomerService {
    margin-right: 40px;
    margin-left: 40px;
  }
}

@media (max-width: 1280px) {
  .AppFooter {
    padding: 80px 40px;
  }
  
  .AppFooter__QuickLinks,
  .AppFooter__Logo,
  .AppFooter__UsefulLinks,
  .AppFooter__SocialLinks,
  .AppFooter__CustomerService {
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .AppFooter {
    padding: 80px 40px;
    font-size: 14px;
  }

  .AppFooter__Logo {
    display: none;
  }
  
  .AppFooter__QuickLinks,
  .AppFooter__Logo,
  .AppFooter__UsefulLinks,
  .AppFooter__SocialLinks,
  .AppFooter__CustomerService {
    margin-right: 10px;
    margin-left: 10px;
  }

  .AppFooter__FollowUsOn li {
    padding: 10px;
  }
}

.AppFooter__DesktopSections {
  display: contents;
}

.AppFooter__MobileSections {
  display: none;
}

@media (max-width: 767px) {
  .AppFooter__DesktopSections {
    display: none;
  }

  .AppFooter__MobileSections {
    display: contents;
  }

  .AppFooter {
    padding: 20px 20px 100px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: none;
  }
  
  .AppFooter__QuickLinks,
  .AppFooter__UsefulLinks,
  .AppFooter__SocialLinks,
  .AppFooter__CustomerService {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }

  .AppFooter__FollowUsOn,
  .AppFooter__DownloadApp {
    border-top: 1px solid #DFE0E6;
  }

  .AppFooter__FollowUsOn span,
  .AppFooter__DownloadApp span {
    margin-top: 16px;
  }

  .AppFooter__QuickLinks li,
  .AppFooter__UsefulLinks li,
  .AppFooter__CustomerService li,
  .AppFooter__FollowUsOn li {
    margin-bottom: 16px;
  }

  .AppFooter__FollowUsOn li {
    padding: 14px;
  }

  .AppFooter__QuickLinks ul,
  .AppFooter__UsefulLinks ul,
  .AppFooter__FollowUsOn ul,
  .AppFooter__CustomerService ul,
  .AppFooter__DownloadApp ul {
    margin-top: 16px;
  }

  .AppFooter__Accordion::before {
    display: none;
  }

  .AppFooter__Accordion {
    width: 100%;
    box-shadow: none !important;
    margin: 0 !important;
    border-top: 1px solid #DFE0E6;
  }

  .AppFooter__Accordion__Title {
    font-weight: 500;
    line-height: 24px;
  }

  #panel1a-header {
    height: 56px !important;
    min-height: 56px !important;
  }
  
  #panel1a-content {
    margin-bottom: 12px;
  }
}