#WhatsappButton {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 80px;
  height: 80px;
  background-color: #13c656;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3.6363637447357178px 4.848484992980957px 29.696969985961914px 1.2121212482452393px #0000004D;
  z-index: 1000;
}

@media (max-width: 768px) {
  #WhatsappButton {
    bottom: 10px;
    right: 20px;
    width: 66px;
    height: 66px;
    box-shadow: 3px 4px 24.5px 1px #0000004D;
  }
}