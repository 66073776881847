@font-face {
  font-family: 'Rubik';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


/* regular */

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


/* light */

@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


/* medium */

@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


/* regular */

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


/* light */

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


/* medium */

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}